/* ==========================================================================
   Form elements
   ========================================================================== */

:root {
    --label-font-size: 12px;
    --label-font-size-large: 16px;

    --border-width: 1px;
    --border-color: var(--colour-slate-200);
    --border-radius: 4px;

    --focus-color: 107 161 203;
    --focus-color-error: 221 98 102;
    --focus-width: 4px;

    --input-height: 32px;
    --input-height-large: 40px;
    --input-color: var(--text-dark);
    --input-inline-icon-size: 16px;

    --checkbox-diamater: 16px;
}

/* Base style reset
   ========================================================================== */

fieldset {
    border: none;
    margin: 0 0 20px 0;
    padding: 0;
    position: relative;

    &.first {
        margin-top: 40px;
    }
}

/* Remove search cancel button */
[type='search']::-webkit-search-cancel-button,
[type='search']::-webkit-search-decoration {
    appearance: none;
}

input::placeholder {
    color: var(--colour-slate-400);
}

/* Form Group
   ==========================================================================

   Seperate form components, like labels, inputs, hints, etc that need to be
   grouped together as a single unit need to be wrapped in a .form__group.
   When inputs are wrapped in a <label>, a .form__group wrapper is not needed.
   To merge multiple inputs/icons/buttons into a single one line element, use
   .input__group

   Large variant: you can apply the large class (-large) to create a large version
   of input and labels.

*/
.form__group {
    margin-bottom: 16px;
}

/* Labels
   ========================================================================== */

.form__label {
    padding: 4px 0;
    display: block;
    font-size: var(--label-font-size);

    &.-required:after {
        content: '*';
        display: inline-block;
        color: var(--colour-japonica);
    }

    .-large > &,
    &.-large {
        font-size: var(--label-font-size-large);
        padding: 8px 0;

        &.-required:after,
        &:has(+ input[required])::after {
            margin-left: 0;
            font-size: 0.6rem;
        }
    }

    &.-light {
        color: var(--theme-light-text);
    }

    &.-dark {
        color: var(--theme-dark-text);
    }

    & .form__tooltip {
        display: inline-block;
        margin: 0 2px;

        & svg {
            display: inline-block;
            margin: -1px 0;
            width: var(--label-font-size);
            height: var(--label-font-size);
        }
    }
}

/* Input
   ==========================================================================

    This covers all <input> fields including:
    * text
    * textarea
   */

.form__input {
    appearance: none;
    background: white;
    background-image: none;
    border: var(--border-width) solid var(--border-color);
    border-radius: var(--border-radius);
    color: var(--input-color);
    display: block;
    font-size: 14px;
    height: var(--input-height);
    line-height: var(--input-height);
    max-width: 100%;
    width: 100%;
    outline: none;
    padding: 0 8px;
    transition:
        background 0.2s,
        border 0.2s,
        box-shadow 0.2s,
        color 0.2s;
    box-shadow: 0 1px 2px 0 rgba(0 0 0 / 0.1);

    &:focus {
        box-shadow: 0 0 0 var(--focus-width) rgba(var(--focus-color) / 0.2);
    }

    &:invalid:focus {
        box-shadow: 0 0 0 var(--focus-width) rgba(var(--focus-color-error) / 0.3);
    }

    .form__group.-large &,
    &.-large {
        height: var(--input-height-large);
        font-size: 16px;
    }

    /* Rounded search fields */
    &.-search {
        padding-left: 16px;
        border-radius: 20px;
    }
}

textarea.form__input {
    height: auto !important;
    line-height: 1.5;
    resize: vertical;
    padding: 8px;
}

/* Fix Safari's Datetime fixed height */
[type='datetime']::-webkit-datetime-edit,
[type='datetime-local']::-webkit-datetime-edit {
    line-height: 1;
    padding: 0;
    margin-bottom: -2px;
}

/* Inline Icons
   ==========================================================================

Displays an inline icons within the form input. Right side only for now.

Come in four flavours:
* -loading
* -hint
* -error
* -search (for search fields)

Can be used in combination with tooltips.

<div class="has-inline-icon">
   <input type="text" class="form__input"
   />
   <span class="form__icon -loading"></span>
</div>

*/

.has-inline-icon,
.has-icon-left,
.has-icon-right {
    position: relative;

    & .form__icon {
        height: var(--input-inline-icon-size);
        width: var(--input-inline-icon-size);
        margin: 0 8px;
        position: absolute;
        top: calc(50% - var(--input-inline-icon-size) / 2);
        z-index: 2;
        mask-repeat: no-repeat;

        &.-hint {
            background-color: #999;
            mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='16' width='16' viewBox='0 0 16 16'%3E%3Cg fill='%23000000' class='nc-icon-wrapper'%3E%3Cpath fill='%23000000' d='M8,0C3.6,0,0,3.6,0,8s3.6,8,8,8s8-3.6,8-8S12.4,0,8,0z M8,14c-3.3,0-6-2.7-6-6s2.7-6,6-6s6,2.7,6,6 S11.3,14,8,14z'%3E%3C/path%3E%3Ccircle data-color='color-2' cx='8' cy='12' r='1'%3E%3C/circle%3E%3Cpath data-color='color-2' d='M7.1,5.5C7.3,5.2,7.6,5,8,5c0.6,0,1,0.4,1,1c0,0.3-0.1,0.4-0.6,0.7C7.8,7.1,7,7.7,7,9v1h2V9 c0-0.2,0-0.3,0.5-0.6C10.1,8,11,7.4,11,6c0-1.7-1.3-3-3-3C6.9,3,5.9,3.6,5.4,4.5L4.9,5.4l1.7,1L7.1,5.5z'%3E%3C/path%3E%3C/g%3E%3C/svg%3E");
        }

        &.-error {
            background-color: var(--status-negative);
            mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='16' width='16' viewBox='0 0 16 16'%3E%3Cg%3E%3Cpath d='M8 0C3.6 0 0 3.6 0 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8zm0 14c-3.3 0-6-2.7-6-6s2.7-6 6-6 6 2.7 6 6-2.7 6-6 6z'/%3E%3Cpath data-color='color-2' d='M7 4h2v5H7z'/%3E%3Ccircle data-color='color-2' cx='8' cy='11' r='1'/%3E%3C/g%3E%3C/svg%3E");
        }

        &.-loading {
            background-color: var(--status-default);
            mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16'%3E%3Ctitle%3El-circle%3C/title%3E%3Cg fill='%23000000'%3E%3Cpath d='M8,16a8,8,0,1,1,8-8A8.009,8.009,0,0,1,8,16ZM8,2a6,6,0,1,0,6,6A6.006,6.006,0,0,0,8,2Z' fill='%23000000' opacity='0.4'%3E%3C/path%3E%3Cpath d='M16,8H14A6.006,6.006,0,0,0,8,2V0A8.009,8.009,0,0,1,16,8Z'%3E%3C/path%3E%3C/g%3E%3C/svg%3E");
            animation: input-spinner 0.9s infinite linear;
        }

        &.-search {
            background-color: var(--text-dark);
            mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cg stroke-linecap='round' stroke-linejoin='round' stroke-width='2' fill='none' stroke='%23000000'%3E%3Cline x1='22' y1='22' x2='15.656' y2='15.656' stroke='%23000000'%3E%3C/line%3E %3Ccircle cx='10' cy='10' r='8'%3E%3C/circle%3E%3C/g%3E%3C/svg%3E");
            mask-size: 16px;
        }

        &.-filter {
            background-color: var(--text-dark);
            mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cg stroke-linecap='round' stroke-linejoin='round' stroke-width='2' fill='none' stroke='%23000000'%3E%3Cpolygon points='22 4 14 12 14 20 10 22 10 12 2 4 2 1 22 1 22 4'%3E%3C/polygon%3E%3C/g%3E%3C/svg%3E");
        }

        &.-clear {
            background-color: var(--text-dark);
            mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16'%3E%3Cg%3E%3Cpath d='M 11 4.300781 L 8 7.300781 L 5 4.300781 L 4.300781 5 L 7.300781 8 L 4.300781 11 L 5 11.699219 L 8 8.699219 L 11 11.699219 L 11.699219 11 L 8.699219 8 L 11.699219 5 Z M 8 0 C 3.601562 0 0 3.601562 0 8 C 0 12.398438 3.601562 16 8 16 C 12.398438 16 16 12.398438 16 8 C 16 3.601562 12.398438 0 8 0 Z M 8 15 C 4.152344 15 1 11.847656 1 8 C 1 4.152344 4.152344 1 8 1 C 11.847656 1 15 4.152344 15 8 C 15 11.847656 11.847656 15 8 15 Z M 8 15 '/%3E%3C/g%3E%3C/svg%3E%0A");
        }
    }
}

.has-icon-left {
    & .form__icon {
        left: 4px;
    }

    & .form__input {
        padding-left: 36px;
    }
}

.has-inline-icon,
.has-icon-right {
    & .form__icon {
        right: 4px;
    }

    & .form__input {
        padding-right: 36px;
    }
}

@keyframes input-spinner {
    0% {
        transform: rotate(0);
    }

    100% {
        transform: rotate(360deg);
    }
}

/* Shared Checkbox/Radio/Toggle styles
   ========================================================================== */

.form__checkbox,
.form__radio,
.form__toggle {
    display: block;
    min-height: 28px;
    padding: 0 0 0 24px;
    position: relative;
    line-height: 28px;
    font-size: var(--label-font-size);

    & .form__icon {
        display: inline-block;
        position: absolute;
        border: 1px solid var(--border-color);
        transition:
            background 0.2s,
            border 0.2s,
            box-shadow 0.2s,
            color 0.2s;
        cursor: pointer;
    }

    & input {
        position: absolute;
        margin: -1px;
        height: 1px;
        width: 1px;
        overflow: hidden;
        clip: rect(0, 0, 0, 0);

        &:focus + .form__icon {
            box-shadow: 0 0 0 var(--focus-width) rgba(var(--focus-color) / 0.2);
        }

        &:hover + .form__icon {
            box-shadow: 0 0 0 var(--focus-width) rgba(var(--focus-color) / 0.2);
        }

        &:checked + .form__icon,
        &:indeterminate + .form__icon {
            background: var(--status-default);
            border-color: var(--status-default);
        }
    }

    &.-light {
        color: var(--theme-light-text);
    }

    /* TODO: Remove first statement once FF acquires :has support */
    &.-required:after,
    &:has(+ input[required])::after {
        content: '*';
        display: inline-block;
        color: var(--colour-red-500);
    }
}

/* Checkboxes and Radio Buttons
   ========================================================================== */

/*
 * Example:
 * <label class="form__checkbox">
 *     <input type="checkbox" />
 *     <span class="form__icon"></span> Label text here
 * </label>
 */

.form__checkbox,
.form__radio {
    & .form__icon {
        background: #fff;
        height: var(--checkbox-diamater);
        width: var(--checkbox-diamater);
        left: 0;
        top: 50%; /* (field size - icon size) / 2; */
        transform: translate(0, -50%);
    }

    & input {
        &:checked + .form__icon {
            &::before {
                display: block;
                content: '';
                position: absolute;
            }
        }
    }
}

.form__checkbox {
    & .form__icon {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 4px;
    }

    & input {
        &:checked + .form__icon {
            &::before {
                height: calc(var(--checkbox-diamater) - 4px);
                width: calc(var(--checkbox-diamater) - 4px);
                background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAxNy4xLjAsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+DQo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4Ig0KCSB2aWV3Qm94PSIwIDAgOCA4IiBlbmFibGUtYmFja2dyb3VuZD0ibmV3IDAgMCA4IDgiIHhtbDpzcGFjZT0icHJlc2VydmUiPg0KPHBhdGggZmlsbD0iI0ZGRkZGRiIgZD0iTTYuNCwxTDUuNywxLjdMMi45LDQuNUwyLjEsMy43TDEuNCwzTDAsNC40bDAuNywwLjdsMS41LDEuNWwwLjcsMC43bDAuNy0wLjdsMy41LTMuNWwwLjctMC43TDYuNCwxTDYuNCwxeiINCgkvPg0KPC9zdmc+DQo=');
            }
        }

        &:indeterminate + .form__icon {
            &::before {
                content: '';
                height: 3px;
                width: 10px;
                background-color: #fff;
            }
        }
    }
}

.form__radio {
    & .form__icon {
        border-radius: 50%;
    }

    & input {
        &:checked + .form__icon {
            &::before {
                height: calc(var(--checkbox-diamater) / 2 - 2px);
                width: calc(var(--checkbox-diamater) / 2 - 2px);
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                background: #fff;
                border-radius: 50%;
            }
        }
    }
}

/* Toggle
   ========================================================================== */

/*
 * Example:
 * <label class="form__toggle">
 *     <input type="checkbox" />
 *     <span class="form__icon"></span> Label text here
 * </label>
 */

.form__toggle {
    padding-left: 40px;

    & .form__icon {
        background: #bbb;
        height: 18px;
        width: 32px;
        top: 6px;
        left: 0;
        border: 0;
        border-radius: 26px;

        &::before {
            background: white;
            border-radius: 50%;
            border: 0;
            content: '';
            display: block;
            height: 16px;
            width: 16px;
            position: absolute;
            top: 1px;
            left: 1px;
            box-shadow: 0 1px 1px rgba(0 0 0 / 0.2);
            transition:
                background 0.2s,
                border 0.2s,
                box-shadow 0.2s,
                color 0.2s,
                left 0.2s;
        }
    }

    & input {
        &:checked + .form__icon::before {
            left: 15px;
        }
        &:active + .form__icon::before {
            background: #eee;
        }
    }

    &.-large {
        padding-left: 50px;

        & .form__icon {
            height: 24px;
            width: 42px;
            top: 4px;

            &::before {
                height: 20px;
                width: 20px;
                top: 2px;
                left: 2px;
            }
        }

        & input:checked + .form__icon::before {
            left: 20px;
        }
    }
}

/* Select Input
   ========================================================================== */

.form__select {
    appearance: none;
    padding: 4px 8px;
    border: 1px solid var(--border-color);
    border-radius: var(--border-radius);
    height: var(--input-height);
    width: 100%;
    line-height: 1.5;
    outline: none;
    color: var(--input-color);
    background: white;
    vertical-align: middle;
    font-size: 14px;
    transition:
        background 0.2s,
        border 0.2s,
        box-shadow 0.2s,
        color 0.2s;
    box-shadow: 0 1px 1px rgba(0 0 0 / 0.1);

    .-large &,
    &.-large {
        font-size: 16px;
        height: var(--input-height-large);
    }

    &:focus {
        box-shadow: 0 0 0 var(--focus-width) rgba(var(--focus-color) / 0.2);
    }

    /* TODO: use :after/image-mask to be able to change dropdown icon colour */
    &:not([size]):not([multiple]) {
        background: #fff
            url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='0 0 12 12'%3E%3Cg fill='%23505560'%3E%3Cpath d='M6.707.293a1,1,0,0,0-1.414,0l-3,3A1,1,0,0,0,3.707,4.707L6,2.414,8.293,4.707A1,1,0,0,0,9.707,3.293Z' fill='%23505560'%3E%3C/path%3E%3Cpath d='M8.293,7.293,6,9.586,3.707,7.293A1,1,0,1,0,2.293,8.707l3,3a1,1,0,0,0,1.414,0l3-3A1,1,0,1,0,8.293,7.293Z'%3E%3C/path%3E%3C/g%3E%3C/svg%3E")
            no-repeat right 8px center;
        padding-right: 32px;
    }

    &[size],
    &[multiple] {
        height: auto;
    }

    &::-ms-expand {
        display: none;
    }

    &.-custom {
        color: #fff;
        border: 1px solid var(--colour-slate-500);
        text-align: left;

        &:not([size]):not([multiple]) {
            background: var(--colour-slate-600)
                url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='0 0 12 12'%3E%3Cg fill='%23ffffff'%3E%3Cpath d='M6.707.293a1,1,0,0,0-1.414,0l-3,3A1,1,0,0,0,3.707,4.707L6,2.414,8.293,4.707A1,1,0,0,0,9.707,3.293Z' fill='%23ffffff'%3E%3C/path%3E%3Cpath d='M8.293,7.293,6,9.586,3.707,7.293A1,1,0,1,0,2.293,8.707l3,3a1,1,0,0,0,1.414,0l3-3A1,1,0,1,0,8.293,7.293Z'%3E%3C/path%3E%3C/g%3E%3C/svg%3E")
                no-repeat right 8px center;
            padding-right: 32px;
        }
    }
}

/* Dropdown / Custom Select Input
   ========================================================================== */

/*
    Displays a custom <select> styled dropdown.

    It has default styling for lists, but can be used to display any
    custom content.

    Example:
    <div class="form__dropdown">
        <button>Select...</button>
        <div class="form__dropdown-content">
            <-- Custom Content -->
        </div>
    </div>


    Select style with embedded links:
    <div class="form__dropdown">
        <button>Select...</button>
        <ul class="form__dropdown-content">
            <li>
                <a href="">Select</a>
            </li>
            <li>
                <a href=""></a>
                <a href=""><svg-icon icon="edit" size="s" /></a>
                <a href=""><svg-icon icon="trashcan" size="s" /></a>
            </li>
            <li>
                <a href="">Nicedale, Sweeton</a>
                <a href=""><svg-icon icon="edit" size="s" /></a>
                <a href=""><svg-icon icon="trashcan" size="s" /></a>
            </li>
        </ul>
    </div>
*/

.form__dropdown {
    position: relative;

    &:focus-within .form__dropdown-content {
        opacity: 1;
        pointer-events: auto;
        transform: translateY(0px);
    }

    &:active .form__dropdown-content,
    &.active .form__dropdown-content {
        opacity: 1;
        pointer-events: auto;
        transform: translateY(0px);
    }
}

.form__dropdown-button {
    appearance: none;
    padding: 4px 8px;
    border: 1px solid var(--colour-slate-500);
    border-radius: var(--border-radius);
    height: var(--input-height);
    width: 100%;
    line-height: 1.5;
    outline: none;
    color: #fff;
    text-align: left;
    vertical-align: middle;
    font-size: 14px;
    transition:
        background 0.2s,
        border 0.2s,
        box-shadow 0.2s,
        color 0.2s;
    box-shadow: 0 1px 1px rgba(0 0 0 / 0.1);
    /* Need to force all buttons to sit under the dropdown */
    position: relative;
    z-index: 1;
    cursor: pointer;

    &:not([size]):not([multiple]) {
        background: var(--colour-slate-600)
            url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='0 0 12 12'%3E%3Cg fill='%23ffffff'%3E%3Cpath d='M6.707.293a1,1,0,0,0-1.414,0l-3,3A1,1,0,0,0,3.707,4.707L6,2.414,8.293,4.707A1,1,0,0,0,9.707,3.293Z' fill='%23ffffff'%3E%3C/path%3E%3Cpath d='M8.293,7.293,6,9.586,3.707,7.293A1,1,0,1,0,2.293,8.707l3,3a1,1,0,0,0,1.414,0l3-3A1,1,0,1,0,8.293,7.293Z'%3E%3C/path%3E%3C/g%3E%3C/svg%3E")
            no-repeat right 8px center;
        padding-right: 32px;
    }

    /* Ensure the button click action always works */
    &:active + .form__dropdown-content,
    &:focus + .form__dropdown-content {
        opacity: 1;
        pointer-events: auto;
        transform: translateY(0);
        transition: transform 0.3s ease;
    }
}

.form__dropdown-content {
    opacity: 0;
    pointer-events: none;
    transform: translateY(-10px);
    position: absolute;
    top: 0;
    list-style: none;
    background-color: var(--colour-slate-600);
    border-radius: 4px;
    padding: 8px;
    margin: 0;
    width: 100%;
    box-shadow: 0 1px 1px rgba(0 0 0 / 0.1);
    z-index: 10;
    transition: opacity 0.2s, transform 0.2s;

    & li {
        font-size: 14px;
        padding: 0;
        text-align: left;
        display: flex;
        align-items: center;
        cursor: pointer; /* Make sure list items are clearly clickable */

        & a,
        & span,
        & label {
            color: #fff;
            display: block;
            width: 100%;
        }

        & a:first-child {
            margin-right: auto;
        }

        & a:not(:first-child) {
            flex: none;
        }

        &:hover {
            background-color: var(--colour-blue-500);
            cursor: pointer;

            & a,
            & span,
            & label {
                color: #fff;
            }
        }
    }

    & svg {
        fill: #fff;
    }
}

/* Range Slider
   ========================================================================== */

input[type='range'] {
    appearance: none;
    max-width: 100%;
    width: 100%;
    height: 4px;
    border-radius: 4px;
    background-color: rgba(255 255 255 / 0.3);
    background-image: linear-gradient(var(--status-default), var(--status-default));
    background-size: 70% 100%;
    background-repeat: no-repeat;
}

input[type='range']:focus {
    outline: none;
}

input[type='range']::-webkit-slider-thumb {
    appearance: none;
    height: 20px;
    width: 20px;
    background: #fff;
    border: 4px solid var(--status-default);
    border-radius: 50%;
    box-shadow: 0 1px 1px rgba(0 0 0 / 0.1);
}

input[type='range']::-moz-range-thumb {
    appearance: none;
    height: 12px;
    width: 12px;
    background: #fff;
    border: 4px solid var(--status-default);
    border-radius: 50%;
    box-shadow: 0 1px 1px rgba(0 0 0 / 0.1);
}

/* Colour input
   ========================================================================== */

input[type='color'] {
    appearance: none;
    margin: 0;
    padding: 0;
    width: var(--input-height);
    height: var(--input-height);
    border: 0;
    background: transparent;
    cursor: pointer;
    border-radius: var(--border-radius);
    box-shadow: 0 1px 1px rgba(0 0 0 / 0.1);
    transition:
        background 0.2s,
        border 0.2s,
        box-shadow 0.2s,
        color 0.2s;

    &:focus {
        box-shadow: 0 0 0 var(--focus-width) rgba(var(--focus-color) / 0.2);
    }
}

input[type='color']::-webkit-color-swatch-wrapper {
    padding: 0;
}

input[type='color']::-webkit-color-swatch {
    border: 0;
    border-radius: var(--border-radius);
    width: 40px;
}

input[type='color']::-moz-color-swatch {
    border: none;
    border-radius: var(--border-radius);
    width: 40px;
}

/* Input Group
   ==========================================================================

   This is a wrapper for when you want to join input elements together
   on a single joint input. e.g. form with an attached submit button.

   It works with either 2 or 3 elements.
   */

.input-group {
    display: flex;
    width: 100%;

    /* Add text or icon at the start of group */
    & .input-group__prefix {
        border: var(--border-width) solid var(--border-color);
        border-radius: var(--border-radius) 0 0 var(--border-radius);
        border-right: 0;
        box-shadow: 0 1px 1px rgba(0 0 0 / 0.1);
        height: var(--input-height);
        min-width: var(--input-height);

        &.-icon {
            display: flex;
            align-items: center;
            justify-content: center;

            & svg {
                fill: var(--theme-light-palette-darker);
                height: 16px;
                width: 16px;
            }
        }
    }

    & .form__input,
    & .form__select,
    & .btn {
        &:first-child:not(:last-child) {
            border-bottom-right-radius: 0;
            border-top-right-radius: 0;
        }

        &:not(:first-child):not(:last-child) {
            margin-left: calc(var(--border-width) * -1);
            border-radius: 0;
        }

        &:last-child:not(:first-child) {
            margin-left: calc(var(--border-width) * -1);
            border-bottom-left-radius: 0;
            border-top-left-radius: 0;
        }

        &:focus {
            z-index: 10;
        }
    }

    /* make selects smaller */
    & .form__select {
        flex-shrink: 2;
    }

    & .form__select.fulltext {
        flex-shrink: 1;
    }

    & input[type='color'] {
        &:first-child:not(:last-child) {
            &::-webkit-color-swatch {
                border-bottom-right-radius: 0;
                border-top-right-radius: 0;
            }

            &::-moz-color-swatch {
                border-bottom-right-radius: 0;
                border-top-right-radius: 0;
            }
        }
    }

    & .btn {
        height: var(--input-height-small);
        min-width: var(--input-height-small);
    }

    .form__group.-large &,
    &.-large {
        & .btn,
        & .btn--icon,
        & .input-group__prefix.-icon,
        & .input-group__prefix.color-swatch {
            height: var(--input-height-large);
            min-width: var(--input-height-large);
        }
    }
}

/* Validation
   ========================================================================== */

.has-error {
    & .form__input {
        border-color: rgba(var(--focus-color-error));

        &:focus {
            box-shadow: 0 0 0 var(--focus-width) rgba(var(--focus-color-error) / 0.3);
        }
    }
}

/* Hints
   ========================================================================== */

.form__hint {
    margin-top: 8px;
    font-size: 13px;
    color: var(--input-color);

    .has-error & {
        color: rgba(var(--focus-color-error));
    }
}

/* Disabled / Read-only
   ========================================================================== */

.form__input,
.form__select {
    &:disabled,
    &.disabled {
        background-color: var(--theme-light-palette-lighter);
        cursor: not-allowed;
        opacity: 0.5;

        &:focus {
            box-shadow: none;
        }
    }
}

input {
    &:disabled,
    &.disabled {
        & + .form__icon {
            background-color: var(--theme-light-palette);
            cursor: not-allowed;
            opacity: 0.5;
        }

        &:hover + .form__icon {
            box-shadow: none;
        }
    }

    &:focus {
        box-shadow: none;
    }

    &.-light {
        color: var(--text-dark);

        & select {
            border-color: var(--body-background-color);

            &:focus {
                border-color: var(--body-background-color) !important;
            }
        }
    }

    &.-dark {
        color: var(--theme-light-text);

        & select {
            border-color: var(--theme-dark-palette-dark);

            &:focus {
                border-color: var(--theme-dark-palette-dark) !important;
            }
        }
    }
}

/* Inline Label
   ==========================================================================

/*  Inline labels are a container element that will position the label and input/select text
Usage:
    <div class="form__inlinelabel">
        <label>Name</label>
        <select class="form__select">
            ...
        </select>
    </div>

    There's also the transparent full height toolbar option:

                Usage:
    <div class="form__inlinelabel -toolbar">
        <label>Name</label>
        <select class="form__select">
            ...
        </select>
    </div>
*/

.form__inlinelabel {
    position: relative;

    & label {
        position: absolute;
        left: calc(8px + var(--border-width));
        top: 4px;
        font-size: 8px;
        color: var(--status-default);
    }

    & .form__select {
        padding-top: 10px;
        padding-bottom: 0;
        font-size: 12px;
    }

    &.-large {
        & label {
            top: 8px;
            font-size: 8px;
        }

        & .form__select {
            padding-top: 14px;
            font-size: 12px;
        }
    }

    &.-toolbar {
        height: 100%;

        & .form__label {
            left: 8px;
            z-index: 1;
        }

        & .form__select {
            position: relative;
            height: 100%;
            border: 0;
            appearance: none;
            box-shadow: none;
            border-radius: 0;
            background-color: transparent;
        }

        &.-dark {
            .form__select {
                color: var(--theme-light-text);
                background-color: var(--theme-dark-palette-light);
            }
        }
    }
}

/* Tags
   ========================================================================== */

.tag {
    height: 24px;
    display: inline-flex;
    align-items: center;
    border: 1px solid var(--theme-light-palette-darker);
    border-radius: var(--border-radius);
    background-color: var(--theme-light-palette-lighter);
    padding: 2px 4px;
    margin-right: 4px;
    font-size: 12px;
    white-space: nowrap;

    & .tag__icon {
        margin: 0 4px;
        opacity: 0.8;
    }

    & .tag__remove,
    & .btn--icon.-small {
        display: inline-flex;
        align-items: center;
        height: 16px;
        width: 16px;
        margin-left: 4px;
        cursor: pointer;
    }

    &.-filter {
        height: 32px;
        border: 0;
        background-color: var(--status-default);
        box-shadow: 0 1px 2px 0 rgba(0 0 0 / 0.1);
        color: #fff;

        & > svg {
            margin-right: 8px;
        }
    }
}

/* Form Layout
   ========================================================================== */

.form__row {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    gap: 16px;
    margin-bottom: 0;

    > * {
        flex: 1;
    }

    .form__checkbox {
        margin-top: 20px;
    }
}

.form__container {
    display: flex;
    width: 100%;
    gap: 32px;
}

.form__fields,
.form__info {
    flex: 1;
}

.form__title {
    font-size: 21px;
    font-weight: 800;
    padding-bottom: 8px;
    border-bottom: 1px solid var(--text-dark);
}

.form__subtitle {
    font-size: 16px;
    font-weight: 800;
}

.form__text {
    font-size: 14px;

    &:first-child {
        margin-top: 0;
    }
}

/* <p class="required-hint"><span>* Required</p> */
.required-hint {
    margin-top: 0;
    font-size: 12px;

    & span {
        color: var(--status-negative);
    }
}

.color-swatch__custom {
    background: linear-gradient(to right, red 0%, #ff0 17%, lime 33%, cyan 50%, blue 66%, #f0f 83%, red 100%);
}
