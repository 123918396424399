/* ==========================================================================
   Tooltips
   ==========================================================================

   NOTE: We use Vue-Tippy for our tooltips. Please refer to the following
         documentation for implementation :
         https://kabbouchi.github.io/vue-tippy/
*/

/* ==========================================================================
   Tippy - General Fixes
   ========================================================================== */

.tippy-tooltip {
    border-radius: 8px;
    background-color: var(--colour-slate-800);
    padding: 8px;
    font-size: 14px;
    font-weight: 500;
}

.tippy-active {
    outline-style: none !important;

    & a {
        outline-style: none !important;
    }
}

.tippy-content {
    overflow-wrap: break-word;
}

.tooltip-id {
    color: var(--colour-slate-400);
}

/* ==========================================================================
   Tooltip Theme for Tippy
   ========================================================================== */

.tooltip-theme {
    background-color: var(colour-slate-800);
    padding: 8px;
    font-size: 14px;
    font-weight: 500;
    border-radius: 8px;
}

/* ==========================================================================
   Dropdown Theme for Tippy
   ==========================================================================

   Vue-Tippy allows for themes.
   NOTE: .dropdown-theme is implemented as:
   v-tippy="{ theme: 'dropdown'}"
*/

.dropdown-theme,
.dropdown-light-theme {
    background-color: var(--colour-slate-800);
    padding: 0;
    min-width: 130px;
    color: var(--colour-slate-50);

    & ul {
        padding: 0;
        margin: 0;
        list-style: none;
    }

    & li {
        text-align: left;
        font-size: 12px;
    }

    & a {
        display: block;
        padding: 8px 16px;
        cursor: pointer;
        color: var(--colour-slate-50);
        transition: 0.2s all ease-in-out;

        &:hover {
            background-color: var(--colour-slate-700);
            color: var(--colour-blue-300);
        }
    }

    & li:first-child a {
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
    }

    & li:last-child a {
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
    }
}

.dropdown-theme__title {
    background-color: var(--colour-slate-700);
    color: var(--colour-slate-50);
    padding: 8px 16px;

    &:first-child {
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
    }
}

.dropdown-light-theme {
    background-color: var(--colour-slate-50);

    & a {
        color: var(--colour-blue-500);
    }

    & a:hover {
        background-color: var(--colour-slate-100);
        color: var(--colour-blue-500);
    }
}

/* ==========================================================================
   Nav Theme
   ========================================================================== */

.nav-theme {
    background-color: var(--theme-dark-palette-dark);
    padding: 0;
    width: 100vw;
    box-sizing: border-box;
    left: -4px;

    & ul {
        padding: 0;
        margin: 0;
        list-style: none;
    }

    & li {
        text-align: left;
        font-size: 16px;

        &.is-active a {
            color: #fff;
        }
    }

    & a {
        display: block;
        color: var(--theme-light-text-darker);
        padding: 10px 15px;
        transition: background-color 0.2s ease-in;

        &:hover {
            background-color: var(--theme-dark-palette-light);
        }
    }
}

/* ==========================================================================
   Confirmation Theme for Tippy
   ========================================================================== */

.confirmation-theme {
    background-color: var(--theme-dark-palette-dark);
    padding: 0;

    &[x-placement^='bottom'] .tippy-arrow {
        border-bottom-color: var(--theme-dark-palette-lighter);
    }
}

.confirmation-theme__header {
    color: #fff;
    background-color: var(--theme-dark-palette-lighter);
    display: flex;
    align-items: center;

    & h5 {
        margin: 0 0 0 10px;
    }

    & a {
        margin-left: auto;
        width: 34px;
            box-sizing: border-box;
            padding: 8px 8px;
    }
}

.confirmation-theme__body {
    padding: 10px;
    background-color: var(--theme-dark-palette);
    text-align: left;

    & form {
        margin-bottom: 0;
    }
}

/* ==========================================================================
   Light Confirmation Theme for Tippy
   ========================================================================== */

   .confirmation-light-theme {
    background-color: var(--theme-light-palette-dark);
    padding: 0;

    &[x-placement^='bottom'] .tippy-arrow {
        border-bottom-color: var(--theme-light-palette-lighter);
    }
}

.confirmation-light-theme__header {
    color: var(--theme-dark-text);
    background-color: var(--theme-light-palette-darker);
    display: flex;
    align-items: center;

    & h5 {
        margin: 0 0 0 10px;
    }

    & a {
        margin-left: auto;
        width: 34px;
            box-sizing: border-box;
            padding: 8px 8px;
    }
}

.confirmation-light-theme__body {
    padding: 10px;
    background-color: var(--theme-light-palette);
    text-align: left;

    & form {
        margin-bottom: 0;
    }
}

/* ==========================================================================
   Custom Select Theme for Tippy
   ========================================================================== */

.select-theme {
    padding: 3px 0 0;
    background-color: #fff;
    border: 1px solid #fff;
    border-radius: 3px !important;
    min-width: 130px;
    box-shadow: 0 1px 15px rgba(27, 31, 35, 0.15);

    & ul {
        padding: 0 0 3px 0;
        margin: 0;
        list-style: none;
    }

    & li {
        font-size: 12px;
        padding-left: 10px;
        text-align: left;

        &:hover {
            background-color: #0074d9;
            cursor: pointer;

            & a,
            & span,
            & label {
                color: #fff;
            }
        }
    }

    & .select-theme__item {
        & a,
        & span {
            padding: 5px 10px 5px 16px;
            color: var(--text-dark);
            display: block;
        }
    }

    & .select-theme__title {
        color: var(--text-dark-light);
    }

    & .select-theme__filter {
        padding: 5px 10px 8px 26px;

        & label {
            padding-bottom: 3px;
            font-size: 11px;
            color: var(--text-dark-light);
        }
    }

    /* Selected tick image */
    & .select-theme__item.-selected span,
    & .select-theme__filter.-selected {
        background-position: left center;
        background-repeat: no-repeat;
        background-size: 10px;
        background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI0LjEuMiwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCA4IDgiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDggODsiIHhtbDpzcGFjZT0icHJlc2VydmUiPgo8c3R5bGUgdHlwZT0idGV4dC9jc3MiPgoJLnN0MHtmaWxsOiM4MDg3OTY7fQo8L3N0eWxlPgo8cGF0aCBjbGFzcz0ic3QwIiBkPSJNNi40LDFMNS43LDEuN0wyLjksNC41TDIuMSwzLjdMMS40LDNMMCw0LjRsMC43LDAuN2wxLjUsMS41bDAuNywwLjdsMC43LTAuN2wzLjUtMy41bDAuNy0wLjdMNi40LDFMNi40LDF6Ii8+Cjwvc3ZnPgo=);

        &:hover {
            background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAxNy4xLjAsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+DQo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4Ig0KCSB2aWV3Qm94PSIwIDAgOCA4IiBlbmFibGUtYmFja2dyb3VuZD0ibmV3IDAgMCA4IDgiIHhtbDpzcGFjZT0icHJlc2VydmUiPg0KPHBhdGggZmlsbD0iI0ZGRkZGRiIgZD0iTTYuNCwxTDUuNywxLjdMMi45LDQuNUwyLjEsMy43TDEuNCwzTDAsNC40bDAuNywwLjdsMS41LDEuNWwwLjcsMC43bDAuNy0wLjdsMy41LTMuNWwwLjctMC43TDYuNCwxTDYuNCwxeiINCgkvPg0KPC9zdmc+DQo=);
        }
    }

    &.select-theme__filter {
        background-position: 8px 8px;
    }
}

/* ==========================================================================
   Hints
   ==========================================================================

    Hints are inline "info" symbols to be used in conjunction with vue-tippy.
*/

.hint {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background-color: #0074d9;
    border-radius: 20px;
    height: 0.8rem;
    width: 0.8rem;
    font: bold 0.6rem 'times new roman', serif;
    top: -0.15rem;
    position: relative;
    margin-left: 2px;
    cursor: pointer;
    outline: 0;
}


/* ==========================================================================
   Icon / Main Nav Theme
   ========================================================================== */

.nav-dropdown-theme {
    background-color: var(--colour-slate-100);
    box-shadow: 0 2px 3px 0 rgba(0 0 0 / 0.2);
    min-width: 260px;
    padding: 16px;
    border-radius: 16px;

    & ul {
        list-style: none;
        margin: 0;
        padding: 0;
    }

    & li.spacer {
        margin-bottom: 32px;
    }

    & li a {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        text-align: left;
        padding: 12px;
        min-height: 44px;
        font-size: 14px;
        color: var(--text-dark);
        transition: all 0.1s ease-in-out;
        border-radius: 8px;
        margin-bottom: 4px;

        &:hover,
        &.is-active {
            background-color: var(--colour-slate-200);
        }

        &:active {
            background-color: var(--colour-slate-100);
        }

        & svg {
            width: 20px;
            min-width: 20px;
            fill: var(--text-dark);
        }

        & span {
            padding-left: 12px;
            white-space: nowrap;
        }
    }

    & li:last-child a {
        margin-bottom: 0;
    }

    .icon-dropdown__footer {
        padding: 16px 0 0 0;
        margin: 0;
        text-align: left;
        color: var(--colour-slate-400);

        & a {
            color: var(--colour-slate-400);
        }
    }

    & h4 {
        text-align: left;
        color: var(--text-dark);
        font-weight: 800;
        margin: 16px 0;

        &:first-child {
            margin-top: 0;
        }
    }
}

/* ==========================================================================
   Form Row Theme for Tippy
   ========================================================================== */

.formrow-theme {
    background-color: var(--colour-slate-300);
    padding: 8px;
    font-size: 14px;
    font-weight: 500;
    border-radius: 8px;
    align-items: center;

    & .tippy-arrow {
        border-bottom-color: var(--colour-slate-300) !important;
    }

    & .tippy-content > div {
        display: flex;
        gap: 8px;
    }

    & .btn--icon {
        margin: 0;
    }

    & .form-select,
    & .form-input {
        margin: 0;
    }
}
